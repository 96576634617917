<main-header></main-header>

<!-- <section class="hero hero-overlay py-5"
  style="background-image: url('./assets/images/hero_home.jpeg');background-position: 50% 80%;">
  <div class="container position-relative h-100">
    <div class="h-100 d-flex flex-column justify-content-center align-items-center pt-5">
      <p class="hero-text mb-0 fs-5 text-light text-center">Lorem ipsum dolor sit amet, consectetuer adipiscing elit.
      </p>
      <h1 class="hero-title mb-3 text-light display-6 fw-medium text-center">Expertos en media y baja tensión.</h1>
    </div>
  </div>
</section> -->

<figure class="m-0">
  <img src="./assets/images/hero_home.jpeg" alt="" class="w-100">
</figure>

@for (catalog of products; track $index) {
<section class="py-5">
  <div class="container">
    <p class="m-0 text-muted text-uppercase fw-bold text-center">Catálogo</p>
    <h3 class="fs-1 mb-4 section-title text-center">{{catalog.title}}</h3>

    <div class="row">
      @for (item of catalog.products; track $index) {
      <div class="col-lg-4 mb-3">
        <article class="card h-100">
          <img src="./assets/images/catalogos/{{item.image}}" class="card-img-top object-fit-contain" alt=""
            width="100%" height="300px">
          <div class="card-body">
            <h5 class="card-title">{{item.title}}</h5>
            <p class="card-text">{{item.text}}</p>
          </div>
        </article>
      </div>
      }
    </div>
  </div>
</section>
}

<main-footer></main-footer>